/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap/dist/css/bootstrap.css";

// Our own variables that override bootstrap
@import "_variables";

// Utility classes
@import "node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "progress-bar";

/*
 * Font Awesome 4.x
 */

// $fa-font-path: "~font-awesome/fonts";  /// maybe??
// @import "font-awesome/scss/font-awesome";

//TODO:  move to main

