/*********
Global Style Guide:
- Styles not applicable directly within component styles should go here.
- Helper classes are listed below. They are usable within sanitised
  HTML and are marked as "HELPER CLASS" for easy identification.
- Helper classes are particularly useful with the Template block, 
  where the style attribute is sanitised, but CSS classes are allowed.
*********/


@import "@ng-select/ng-select/themes/material.theme.css";
// @import '../../app/ng-select-theme';


.formFieldWidth380 .mat-form-field-infix {
  width: 380px;
}

.ken-tree-invisible {
  display: none;
  opacity: 0;
  transition: all 500ms ease;
}

.ken-tree ul,
.ken-tree li {
  margin: 0;
  list-style-type: none;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;

  cursor: pointer;
}

.ken-tree li>ul {
  background: rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.ken-tree li>ul>.mat-tree-node li,
.mat-nested-tree-node li {
  //   background: rgba(0, 0, 0, 0.04);
  //  font-size: 12px
}

.mat-nested-tree-node ul li {
  min-height: 28px !important;
}

:active,
:focus,
:visited {
  outline: none;
  -moz-outline-style: none;
}

button:focus {
  outline: none !important;
}

.page-content {
  padding: 16px;
}

.mat-tree-node {
  // padding-left: 16px;
}

button.active {
  // background-color: silver!important;
  opacity: 0.4;
  cursor: default;
}

.menu-item--active {
  // background: rgba(0, 0, 0, 0.2);
  background-color: whitesmoke;
}

.mat-mdc-menu-item {
  padding: 10px 16px;
}

.messagedesk {
  position: fixed;
  right: 10px;
  top: 10px;
  max-width: 200px;
  z-index: 10;

  .card {
    background-color: smokewhite;
    width: 16vw;
    min-width: 220px;
    max-width: 275px;
    box-shadow: inset -55px 2px 16px -55px rgba(0, 0, 0, 0.2), 5px 12px 7px -12px rgba(0, 0, 0, 0.2);

    .card-body {
      padding: 1rem 0.5rem 1rem 0.5rem !important;
      font-size: small;

      @media (min-height: 901px) {
        font-size: 1rem;
        padding: 0.6rem !important;
      }
    }
  }
}

.k-help-badge {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 20px;
  height: 20px;
  line-height: 20px;
  top: -13px;
  right: -18px;
  background-color: pink;
  cursor: POINTER;
  font-size: small;

  &:hover {
    background-color: green;
  }
}

.spacer {
  flex: 1 1 auto;
}

.page-content {
  margin: 1em;
}

.app-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-side {
  margin-left: 10px;
}

.mat-drawer-side li .mat-icon {
  margin-right: 10px;
}

.mat-mdc-menu-item {
  text-transform: capitalize;
}




.form-container>* {
  width: 100%;
}

dynamic-material-form-control:not(:last-child) {
  margin-right: 40px;
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 1);
}

.mat-mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 1em;
}

// HELPER CLASS
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  display: inline-block;
}

.ng-dropdown-panel-items {
  font-size: 1rem;

  b {
    font-weight: 500;
  }
}

mat-form-field,
ng-select {
  width: 100%;
}

.ng-select {

  .ng-select-container {
    overflow: visible !important;
  }
}

formly-field {
  margin-bottom: 1rem
}

.k-thumbnails {
  img {
    margin-right: 5px;
    opacity: .5;

    &:hover {
      opacity: 1;
    }

    &.default-thumb {
      opacity: 1;
      border: 2px solid black;
    }
  }
}


formly-array-type {
    .mat-mdc-card {
      margin-right: 16px;
    }

}

// Fix for cut-off Formly Material label
.mdc-text-field--filled::before{
  content: "" !important;
}

.mdc-text-field--disabled, 
.mdc-text-field--disabled .mat-mdc-input-element {
  color: black !important;
}

.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6)
}

.mdc-text-field--disabled mat-label::after {
  content: "(read-only)";
}

.block-comment-container {
  width: 95%;
  margin-left: 2%;
  position: relative;
}

// Kendraio3ca31f6bcb87c68721363847192dbd57