@use '@angular/material' as mat;
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css';
@import "@fortawesome/fontawesome-free/css/all.css";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Include the common styles for Angular Material. 
// We include this here so that you only have to load a single css file for Angular Material in your app.
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes up to vM2: https://material.io/design/color/ 
$kendraio-app-primary: mat.m2-define-palette(mat.$m2-green-palette);
$kendraio-app-accent: mat.m2-define-palette(mat.$m2-orange-palette, A200, A100, A400);
$kendraio-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$container-padding: 20px;

// Create custom MUI theme.
$kendraio-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $kendraio-app-primary,
    accent: $kendraio-app-accent,
    warn: $kendraio-app-warn
  ),
  typography: mat.m2-define-typography-config(),
  density: -1,
));
 
@include mat.all-component-themes($kendraio-app-theme);




// Global style, not part of the custom theme.
html, body { height: 100%; overflow: hidden; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 10px 20px 20px;
}

.mdc-button.mat-mdc-button > .mat-icon, 
.mdc-button.mat-mdc-unelevated-button > .mat-icon, 
.mdc-button.mat-mdc-raised-button > .mat-icon, 
.mdc-button.mat-mdc-outlined-button > .mat-icon {
  font-size: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
.mb-1 {
  margin-bottom: 1em;
}
.mt-1 {
  margin-top: 1em;
}

dynamic-material-form-control .mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.in {
  display: block;
}
.out {
  display: none;
}

.schedule-popup{
  // display:none;
  position: absolute;
  box-shadow: 3px 3px 3px rgba(55,3, 3, .5);
  padding: 16px;
  background-color: silver;
transform: translatey(-80px);
transition: all 2s ease;
z-index: 9;
&.in{
  display: block;
//  transform: translate(80px,-80px);
}
}

.editor-config-column .mat-expansion-panel-body {
  padding: 0;
}

app-workflow-cell-renderer app-actions-block.block-wrapper {
  margin: 0;
}

.tag-list {
  font-size: 0.8em;
  margin-bottom: 0.4em;
  .tag {
    background: #eee;
    padding: 0.2em 0.4em;
    border-radius: 0.4em;
    margin-right: 0.4em;
  }
  .version-tag {
    background-color: #7fbbe3;
  }
}
